import { Buffer } from 'buffer'

export function bufferToFile(
  buffer: ArrayBuffer | Uint8Array,
  fileName: string,
  mimeType: string = 'application/octet-stream',
): File {
  const blob = new Blob([buffer], { type: mimeType })
  return new File([blob], fileName, { type: mimeType })
}

export async function fileToBuffer(
  file: File,
): Promise<string> {
  console.log(file)

  return Buffer.from(
    new Uint8Array(await file.arrayBuffer()),
  ).toString('base64')
}
