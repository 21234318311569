<script setup lang="ts">
const { globalTextData } = storeToRefs(useLanguage())
const { company } = storeToRefs(useCompany())
</script>

<template>
  <footer
    class="flex w-full gap-1 p-1 fixed -bottom-0 py-2.5 text-white bg-[rgba(1,32,54,0.949)]"
  >
    <div class="w-2/4 flex flex-col gap-1">
      <p
        class="text-center tracking-normal text-[14px] font-light"
        :style="{
          color: company?.colorConfig.footer_above_text,
        }"
      >
        <i
          class="pi pi-phone"
          :style="{
            color: company?.colorConfig.footer_above_icon,
          }"
        ></i>
        {{ company?.phone }}
      </p>
      <Button
        class="w-full font-bold border-2 bg-transparent rounded-xl"
        :style="{
          borderColor:
            company?.colorConfig.footer_btn_stroke,
          color: company?.colorConfig.footer_btn_text,
        }"
        :label="globalTextData?.call_btn"
        severity="secondary"
        raised
      />
    </div>
    <div class="w-2/4 flex flex-col gap-1">
      <p
        class="text-center text-[14px] font-light"
        :style="{
          color: company?.colorConfig.footer_above_text,
        }"
      >
        <i
          class="pi pi-envelope"
          :style="{
            color: company?.colorConfig.footer_above_icon,
          }"
        ></i>
        {{ company?.email }}
      </p>
      <Button
        class="w-full font-bold border-2 bg-transparent rounded-xl"
        :style="{
          borderColor:
            company?.colorConfig.footer_btn_stroke,
          color: company?.colorConfig.footer_btn_text,
        }"
        :label="globalTextData?.mail_btn"
        severity="secondary"
        raised
      />
    </div>
  </footer>
</template>
