import InputText from '../primevue/inputtext/index'
import Button from '../primevue/button/index'
import AutoComplete from '../primevue/autocomplete'
import Select from './select'
import Toast from './toast'
import Dialog from './dialog'

export default {
  InputText,
  Button,
  AutoComplete,
  Select,
  Toast,
  Dialog,
}
