export default {
  root: ({ props }) => ({
    class: [
      'w-96',
      'rounded-md',
      {
        '-translate-x-2/4':
          props.position === 'top-center' ||
          props.position === 'bottom-center',
      },
    ],
  }),
  message: ({ props }) => ({
    class: [
      'mb-4',
      'rounded-md',
      'w-full',
      'border',
      'border-transparent',
      'backdrop-blur-md',
      'shadow-md',
      {
        'bg-blue-50 dark:bg-blue-500/20':
          props.message.severity === 'info',
        'bg-green-50 dark:bg-green-500/20':
          props.message.severity === 'success',
        'bg-surface-50 dark:bg-surface-800':
          props.message.severity === 'secondary',
        'bg-orange-50 dark:bg-orange-500/20':
          props.message.severity === 'warn',
        'bg-red-50 dark:bg-red-500/20':
          props.message.severity === 'error',
        'bg-surface-950 dark:bg-surface-0':
          props.message.severity === 'contrast',
      },
      {
        'border-blue-200 dark:border-blue-500/20':
          props.message.severity === 'info',
        'border-green-200 dark:border-green-500/20':
          props.message.severity === 'success',
        'border-surface-300 dark:border-surface-500/20':
          props.message.severity === 'secondary',
        'border-orange-200 dark:border-orange-500/20':
          props.message.severity === 'warn',
        'border-red-200 dark:border-red-500/20':
          props.message.severity === 'error',
        'border-surface-950 dark:border-surface-0':
          props.message.severity === 'contrast',
      },
      {
        'text-blue-700 dark:text-blue-300':
          props.message.severity === 'info',
        'text-green-700 dark:text-green-300':
          props.message.severity === 'success',
        'text-surface-700 dark:text-surface-300':
          props.message.severity === 'secondary',
        'text-orange-700 dark:text-orange-300':
          props.message.severity === 'warn',
        'text-red-700 dark:text-red-300':
          props.message.severity === 'error',
        'text-surface-0 dark:text-surface-950':
          props.message.severity === 'contrast',
      },
    ],
  }),
  messageContent: ({ props }) => ({
    class: [
      'flex',
      'p-3',
      {
        'items-start': props.message.summary,
        'items-center': !props.message.summary,
      },
    ],
  }),
  messageIcon: ({ props }) => ({
    class: [
      props.message.severity === 'contrast' ||
      props.message.severity === 'secondary'
        ? 'w-0'
        : 'w-4 h-4 mr-2',
      'text-lg',
    ],
  }),
  messageText: {
    class: [
      'text-base',
      'leading-normal',
      'ml-2',
      'flex-1',
    ],
  },
  summary: {
    class: 'font-medium block',
  },
  detail: ({ props }) => ({
    class: [
      'block',
      'text-sm',
      {
        'text-surface-700 dark:text-surface-0':
          props.message.severity !== 'contrast',
        'text-surface-0 dark:text-surface-950':
          props.message.severity === 'contrast',
        'mt-2': props.message.summary,
      },
    ],
  }),
  closebutton: ({ props }) => ({
    class: [
      'flex',
      'items-center',
      'justify-center',
      'w-7',
      'h-7',
      'ml-auto',
      'relative',
      'rounded-full',
      'bg-transparent',
      'transition',
      'duration-200',
      'ease-in-out',
      'hover:bg-gray-100 dark:hover:bg-gray-700',
      'focus:outline-none',
      'focus:ring',
      {
        'focus:ring-blue-500 dark:focus:ring-blue-400':
          props.severity === 'info',
        'focus:ring-green-500 dark:focus:ring-green-400':
          props.severity === 'success',
        'focus:ring-orange-500 dark:focus:ring-orange-400':
          props.severity === 'warn',
        'focus:ring-red-500 dark:focus:ring-red-400':
          props.severity === 'error',
      },
    ],
  }),
  transition: {
    enterFromClass: 'opacity-0 translate-y-4',
    enterActiveClass:
      'transition-transform transition-opacity duration-300',
    leaveFromClass: 'opacity-100',
    leaveActiveClass: 'transition-opacity duration-200',
    leaveToClass: 'opacity-0',
  },
}
