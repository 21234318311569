<script setup lang="ts">
const { selectedLanguage } = storeToRefs(useLanguage())
const { company } = storeToRefs(useCompany())
</script>

<template>
  <div class="tracking-wider font-mono">
    <DashboardHeader class="z-20" v-if="company" />
    <slot />
    <DashboardFooter class="z-10" v-if="company" />
    <DashboardLanguageModal v-if="!selectedLanguage" />
  </div>
</template>
