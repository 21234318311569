import type { ColorConfig } from '~/types/colorConfig'
import type { Company } from '~/types/company'

export const defaultValue: ColorConfig = {
  id: 0,
  company_id: 0,
  company: {} as Company,
  back_btn_text: '#FFFFFF',
  back_btn_icon: '#000000',
  back_btn_bg: '#FFDD00',
  header_bg: '#FFFFFF',
  header_text_fill: '#FF0000',
  header_text_stroke: 'FFFFFF',
  header_icon: '#FF0000',
  footer_btn_text: '#FFFFFF',
  footer_btn_stroke: '#FFDD00',
  footer_above_text: '#FFDD00',
  footer_above_icon: '#FFDD00',
}

export const useCompany = defineStore('company', () => {
  const baseApiURL = useRuntimeConfig().public.apiUrl
  const company: Ref<Company | undefined> = useState(
    'company:me',
    () => undefined,
  )
  const loading: Ref<boolean> = useState(
    'company:loading',
    () => false,
  )

  async function createCompany(companyData: Company) {
    const { logo, ...rest } = companyData


    const logoData = await fileToBuffer(logo)
    try {
      const newCompany = await $fetch<any>(
        `${baseApiURL}/api/companies/`,
        {
          method: 'post',
          body: {
            ...rest,
            logo: logoData,
          },
        },
      )
      company.value = newCompany.data
      console.log('newCompany', company.value)

      return newCompany.data
    } catch (e) {
      console.error(e)
      
      throw e;
    }
  }

  async function updateCompany(companyData: Company) {
    try {
      const { logo, ...rest }: any = companyData

      console.log(logo)


      const logoData = await fileToBuffer(logo)

      const updatedCompany: Company = await $fetch(
        `${baseApiURL}/api/companies/${companyData.id}`,
        {
          method: 'patch',
          body: {
            ...rest,
            logo: logoData,
          },
        },
      )

      company.value = updatedCompany
      console.log('updatedCompany', updatedCompany)
      console.log('company', company)
      return updatedCompany
    } catch (e) {
      console.error(e)

      throw e
    }
  }

  async function updateColorConfig(
    colorConfig: ColorConfig,
  ) {
    try {
      //ts-ignore
      await $fetch(`${baseApiURL}/api/colorConfig`, {
        method: 'post',
        body: {
          ...colorConfig,
          company_id: company.value?.id,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  async function getCompanyHelper(
    companyId: number,
  ): Promise<Company> {
    loading.value = true

    try {
      //ts-ignore
      const fetchedCompany: Company = await $fetch(
        `${baseApiURL}/api/companies/${companyId}`,
        {
          method: 'GET',
        },
      )
      return fetchedCompany
    } catch (e) {
      console.error(e)
      return {} as Company
    } finally {
      loading.value = false
    }
  }

  async function fetchCompanyData(
    companyId: number,
  ): Promise<Company> {
    try {
      //ts-ignore
      return await $fetch(
        `${baseApiURL}/api/companies/${companyId}`,
        {
          method: 'GET',
        },
      )
    } catch (e) {
      console.error(e)
      return {} as Company
    }
  }

  async function getCompany(companyId: number) {
    loading.value = true

    try {
      const fetchedCompany =
        await fetchCompanyData(companyId)
      company.value = fetchedCompany
    } finally {
      loading.value = false
    }
  }

  async function getCompanyWithDefaultColorConfig(
    companyId: number,
  ) {
    loading.value = true

    try {
      const fetchedCompany =
        await fetchCompanyData(companyId)

      const mergedColorConfig: any = {
        ...defaultValue,
        ...fetchedCompany.colorConfig,
      }

      Object.keys(mergedColorConfig).forEach((key) => {
        const typedKey = key as keyof ColorConfig
        if (mergedColorConfig[typedKey] === null) {
          mergedColorConfig[typedKey] =
            defaultValue[typedKey]
        }
      })

      company.value = {
        ...fetchedCompany,
        colorConfig: mergedColorConfig,
      }
    } finally {
      loading.value = false
    }
  }

  function deleteCompany(companyId: number) {
    try {
      $fetch(`${baseApiURL}/api/companies/${companyId}`, {
        method: 'DELETE',
      })
    } catch (e) {
      console.error(e)
    }
  }

  async function deleteLanguage(languageId: number) {
    try {
      await $fetch(
        `${baseApiURL}/api/companyLanguage/${languageId}/companyId/${company.value?.id}`,
        {
          method: 'DELETE',
        },
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function getAllCompanys(): Promise<
    Company[] | undefined
  > {
    loading.value = true

    try {
      return await $fetch(`${baseApiURL}/api/companies/`, {
        method: 'GET',
      })
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  return {
    company,
    loading,

    getCompany,
    getAllCompanys,

    createCompany,
    updateCompany,

    deleteCompany,
    deleteLanguage,

    getCompanyWithDefaultColorConfig,
    updateColorConfig,
  }
})
