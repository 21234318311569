<script setup lang="ts">
const { globalTextData, loading, languages } =
  storeToRefs(useLanguage())

const language = ref<ILanguage>(null)

const handleClick = async () => {
  await useLanguage().selectLanguage(language)
}
</script>

<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 pt-10"
  >
    <div
      class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
    >
      <div
        class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full justify-center items-center flex"
      >
        <DashboardLoadingSpinner v-if="loading" />

        <div v-else class="max-w-2xl relative p-4 w-full">
          <div
            class="relative bg-white rounded-3xl shadow dark:bg-gray-700"
          >
            <div
              class="p-3 rounded-t flex justify-between items-center"
            >
              <div
                class="flex items-center text-xl font-extrabold text-center"
              >
                Select the language
              </div>
            </div>
            <div class="p-3">
              <div class="font-semibold">Language</div>
              <div>
                <Select
                  v-model="language"
                  :options="languages"
                  optionLabel="name"
                  placeholder="Please select one"
                  class="w-full p-2.5 border-gray-300 text-sm"
                />
              </div>
              <div class="mt-4 flex justify-center">
                <button
                  @click="handleClick"
                  :disabled="!language"
                  class="text-2xl pb-1.5 font-bold w-full rounded-2xl bg-yellow-400 text-black border-none disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  CONFIRM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.app-layout-main__qr-code-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 12px 82px;
}

.app-layout-main__qr-code {
  width: 100%;
  max-width: 490px;
}
</style>
